<template>
  <v-dialog
    persistent
    scrollable
    max-width="450"
    :fullscreen="$vuetify.breakpoint.mobile"
    :value="true"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-account-multiple-check-outline</v-icon>
        <div class="text-truncate">
          <span>An-/Abwesenheit eintragen</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-text class="pt-6">

        <v-row>
          <v-col>
            <StudentSelect
              :value="students"
              :items="data.allStudents"
              @change="students = $event"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <HourNumberSelect
              label="Von"
              :date="startDate"
              :hourNumber="startHourNumber"
              @change:date="startDate = $event"
              @change:hourNumber="startHourNumber = $event"
            />
          </v-col>
          <v-col>
            <HourNumberSelect
              label="Bis"
              :date="endDate"
              :hourNumber="endHourNumber"
              @change:date="endDate = $event"
              @change:hourNumber="endHourNumber = $event"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-btn-toggle
              rounded
              class="white--text"
              v-model="isAbsent"
              @change="absenceReason = null"
            >
              <v-btn
                :color="true !== isAbsent ? 'green' : 'grey'"
                :outlined="false !== isAbsent"
                :disabled="loading"
                :value="false"
              >
                <v-icon
                  color="white"
                  class="mr-1"
                >
                  mdi-emoticon-happy-outline
                </v-icon>
                Anwesend
              </v-btn>
              <v-btn
                :color="false !== isAbsent ? 'red' : 'grey'"
                :outlined="true !== isAbsent"
                :disabled="loading"
                :value="true"
              >
                Abwesend
                <v-icon
                  color="white"
                  class="ml-1"
                >
                  mdi-emoticon-sad-outline
                </v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              clearable
              dense
              outlined
              hide-details
              label="Abwesenheit Grund"
              item-text="name"
              item-value="@id"
              :items="absenceReasons"
              :disabled="loading || true !== isAbsent"
              v-model="absenceReason"
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  link
                  small
                  class="absence-reason"
                  :color="item.color"
                >
                  <v-avatar left>
                    <v-icon v-text="item.icon" />
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip
                  link
                  class="absence-reason"
                  :color="item.color"
                >
                  <v-avatar left>
                    <v-icon v-text="item.icon" />
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              outlined
              dense
              hide-details
              rows="3"
              label="Bemerkung"
              :counter="255"
              :rules="[length(255)]"
              :disabled="loading"
              v-model.trim="comment"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="$emit('close')"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="primary"
          :loading="loading"
          @click="saveAndClose()"
        >
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState } from 'vuex'
import apiClient from '@/api-client'
import { length } from '@/helpers/validators'
import StudentSelect from '@/pages/absences.page/absences-table/student-select'
import HourNumberSelect from '@/pages/absences.page/absences-table/hour-number-select'

export default {
  name: 'EditDialog',
  components: {
    StudentSelect,
    HourNumberSelect
  },
  props: {
    data: Object
  },
  data: () => ({
    students: [],
    startDate: null,
    startHourNumber: null,
    endDate: null,
    endHourNumber: null,
    isAbsent: null,
    absenceReason: null,
    comment: '',
    loading: false,
    error: null,
    length
  }),
  computed: {
    ...mapState('common', [
      'absenceReasons',
    ])
  },
  created() {
    this.students = [...(this.data?.students || [])]
    this.startDate = this.data?.startDate || null
    this.startHourNumber = this.data?.startHourNumber || null
    this.endDate = this.data?.endDate || null
    this.endHourNumber = this.data?.endHourNumber || null
    const { isAbsent, absenceReason, comment } = this.data?.studentAbsence || {}
    this.isAbsent = typeof isAbsent == 'boolean' ? isAbsent : null
    this.absenceReason = absenceReason
    this.comment = comment || ''
  },
  methods: {
    async saveAndClose() {
      this.error = null
      this.loading = true
      try {
        const { startDate, startHourNumber, endDate, endHourNumber, isAbsent, absenceReason, comment } = this
        const students = this.students.map(s => s.student)
        apiClient.studentAbsences.updateByStudents(students, {
          startDate,
          startHourNumber,
          endDate,
          endHourNumber,
          isAbsent,
          cameLater: false !== isAbsent ? 0 : undefined,
          leftEarlier: false !== isAbsent ? 0 : undefined,
          absenceReason,
          comment: comment || undefined
        })
        this.$emit('close', true)
      } catch (err) {
        console.error(err)
        this.error = apiClient.getErrorReason(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.absence-reason {
  width: 95%;
}
</style>
