import { render, staticRenderFns } from "./register-student-absence-dialog.vue?vue&type=template&id=aaf3c16a&scoped=true"
import script from "./register-student-absence-dialog.vue?vue&type=script&lang=js"
export * from "./register-student-absence-dialog.vue?vue&type=script&lang=js"
import style0 from "./register-student-absence-dialog.vue?vue&type=style&index=0&id=aaf3c16a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf3c16a",
  null
  
)

export default component.exports