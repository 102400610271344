<template>
  <v-dialog
    persistent
    scrollable
    max-width="450"
    :value="show"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon class="mr-4">mdi-account-multiple-check-outline</v-icon>
        <div class="text-truncate">
          <span>Abwesenheit melden</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-text>

        <v-row
          dense
          class="mt-3"
        >
          <v-col>
            <v-autocomplete
              v-model="studentName"
              :loading="loadingStudents"
              :items="students.map(s => s.displayName)"
              :search-input.sync="studentSearchTerm"
              hide-no-data
              autofocus
              label="Schüler/-in"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-select
              clearable
              label="Abwesenheit Grund"
              item-text="name"
              item-value="@id"
              :items="absenceReasons"
              :disabled="loading"
              v-model="absenceReason"
            >
              <template v-slot:selection="{ item }">
                <v-chip
                  link
                  class="absence-reason"
                  :color="item.color"
                >
                  <v-avatar left>
                    <v-icon v-text="item.icon" />
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip
                  link
                  class="absence-reason"
                  :color="item.color"
                >
                  <v-avatar left>
                    <v-icon v-text="item.icon" />
                  </v-avatar>
                  {{ item.name }}
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-menu
              v-model="datesMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="datesText"
                  label="Tage"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dates"
                :min="minDate"
                :max="maxDate"
                :allowedDates="isDateAllowed"
                range
                first-day-of-week="1"
                no-title
                show-week
                color="primary"
                @change="datesMenu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="$emit('close')"
        >
          Abbrechen
        </v-btn>
        <v-btn
          v-show="canSave"
          color="primary"
          :loading="loading"
          @click="saveAndClose()"
        >
          Melden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import apiClient from '@/api-client'
import { dateRangeToString } from '@/helpers/datetime'

export default {
  name: 'RegisterStudentAbsenceDialog',
  props: {
    show: Boolean
  },
  data: () => ({
    error: null,
    loading: false,
    loadingStudents: false,
    studentSearchTerm: null,
    students: [],
    studentName: null,
    absenceReason: null,
    dates: [],
    datesMenu: false
  }),
  computed: {
    ...mapState('schoolCalendar', ['section']),
    ...mapGetters('schoolCalendar', ['allowedDates']),
    absenceReasons() {
      return this.$store.state.common.absenceReasons
    },
    student() {
      const { studentName } = this
      return studentName && this.students.find(({ displayName }) => displayName === studentName)
    },
    minDate() {
      return this.section?.startsOn
    },
    maxDate() {
      return this.section?.endsOn
    },
    startDate() {
      return this.dates[0]
    },
    endDate() {
      return this.dates[1]
    },
    datesText() {
      if (!this.startDate) return null
      return dateRangeToString(this.startDate, this.endDate)
    },
    canSave() {
      return !!(this.student && this.startDate && this.endDate)
    }
  },
  watch: {
    show(isShown) {
      this.reset()
      if (isShown) {
        this.init()
      }
    },
    async studentSearchTerm(term) {
      if (!(term?.length > 2 && term !== this.studentName)) return
      this.error = null
      this.loadingStudents = true
      try {
        const { canWriteStudentAbsenceByStudent } = this.$store.getters
        this.students = (await apiClient.students.search(term))
          .filter(canWriteStudentAbsenceByStudent)
      } catch (err) {
        console.error(err)
        this.error = apiClient.getErrorReason(err)
      } finally {
        this.loadingStudents = false
      }
    }
  },
  methods: {
    reset() {
      this.error = null
      this.loading = false
      this.loadingStudents = false
      this.studentSearchTerm = null
      this.students = []
      this.studentName = null
      this.absenceReason = null
      this.dates = []
      this.datesMenu = false
    },
    async init() {
      this.loading = true
      await this.$store.dispatch('common/fetchCollectionOnce', 'absenceReasons')
      this.loading = false
    },
    isDateAllowed(date) {
      return this.allowedDates.includes(date)
    },
    async saveAndClose() {
      if (!this.canSave) return
      this.error = null
      this.loading = true
      try {
        const { startDate, endDate } = this
        const swapDates = endDate < startDate
        await apiClient.studentAbsences.updateByStudents([this.student], {
          startDate: swapDates ? endDate : startDate,
          endDate: swapDates ? startDate : endDate,
          isAbsent: true,
          absenceReason: this.absenceReason
        })
        this.$emit('close', true)
      } catch (err) {
        console.error(err)
        this.error = apiClient.getErrorReason(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.absence-reason {
  width: 95%;
}
</style>
