<template>
  <v-dialog
    persistent
    scrollable
    max-width="450"
    :fullscreen="$vuetify.breakpoint.mobile"
    :value="true"
  >
    <v-card>
      <v-card-title class="subtitle-1 text-sm-h6 flex-nowrap">
        <v-icon
          class="mr-4"
          color="error"
        >mdi-alert-outline</v-icon>
        <div class="text-truncate">
          <span>Abwesenheiten löschen</span>
        </div>
      </v-card-title>

      <v-divider/>

      <v-card-text class="pt-6">

        <v-row>
          <v-col>
            <StudentSelect
              :value="students"
              :items="data.allStudents"
              @change="students = $event"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <HourNumberSelect
              label="Von"
              :date="startDate"
              :hourNumber="startHourNumber"
              @change:date="startDate = $event"
              @change:hourNumber="startHourNumber = $event"
            />
          </v-col>
          <v-col>
            <HourNumberSelect
              label="Bis"
              :date="endDate"
              :hourNumber="endHourNumber"
              @change:date="endDate = $event"
              @change:hourNumber="endHourNumber = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-title
        v-if="error"
        class="justify-center"
      >
        <v-alert
          dense
          outlined
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-card-title>

      <v-divider/>

      <v-card-actions class="px-6">
        <v-spacer />
        <v-btn
          text
          :disabled="loading"
          @click="$emit('close')"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="error"
          :loading="loading"
          @click="submitAndClose()"
        >
          Löschen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import apiClient from '@/api-client'
import StudentSelect from '@/pages/absences.page/absences-table/student-select'
import HourNumberSelect from '@/pages/absences.page/absences-table/hour-number-select'

export default {
  name: 'DeleteDialog',
  components: {
    StudentSelect,
    HourNumberSelect
  },
  props: {
    data: Object
  },
  data: () => ({
    students: [],
    startDate: null,
    startHourNumber: null,
    endDate: null,
    endHourNumber: null,
    loading: false,
    error: null
  }),
  created() {
    this.students = [...(this.data?.students || [])]
    this.startDate = this.data?.startDate || null
    this.startHourNumber = this.data?.startHourNumber || null
    this.endDate = this.data?.endDate || null
    this.endHourNumber = this.data?.endHourNumber || null
  },
  methods: {
    async submitAndClose() {
      this.error = null
      this.loading = true
      try {
        const { startDate, startHourNumber, endDate, endHourNumber } = this
        const students = this.students.map(s => s.student)
        await apiClient.studentAbsences.removeByStudents(students, {
          startDate,
          startHourNumber,
          endDate,
          endHourNumber,
        })
        this.$emit('close', true)
      } catch (err) {
        console.error(err)
        this.error = apiClient.getErrorReason(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
